<template>
  <v-container fluid>
    <!-- <Breadcrumbs /> -->
    <!-- Knowlarity Creds Input -->
    <v-flex class="mx-4">
      <v-card class="rounded secondarygrad mb-4">
        <v-row style="margin: 0px">
          <v-col>
            <v-card
              color="transparent"
              elevation="0"
              style="padding: 10px; text-align: center !important"
            >
              <v-card-text style="margin-bottom: 25px">
                <!-- <h2 style="margin-bottom: 30px">
                      Lorem ipsum
                    </h2> -->
                <div
                  align="center"
                  style="margin-bottom: 10px; margin-top: 20%"
                >
                  <h1 style="margin-bottom: 40px">Godial + Zoho</h1>
                </div>
                <div style="padding: 5px 15px; text-align: center">
                  <!-- <h5
                        style="
                          line-height: 17px;
                          font-weight: 500;
                          font-size: 14px;
                        "
                      >
                        How to use?
                      </h5> -->

                  <div style="text-align: left">
                    <p
                      style="
                            margin-top: 20px;
                            font-size: 18px;
                            font-weight: 600;
                            margin-bottom: 10px;
                          "
                    >
                      How it works?
                    </p>
                    <p>
                      GoDial allows you to integrate your Zoho CRM and make it
                      easy for you to auto dial on your zoho leads. Any lead or
                      contact created in zoho will automatically appear on the
                      selected list in GoDial. Once the data arrives you can
                      call the list, after the call, the Call info will be auto
                      synced with Zoho.
                      <br />
                    </p>
                  </div>
                  <p class="text-left">
                    For more details visit :
                    <a
                      target="_blank"
                      href="https://help.indiamart.com/knowledge-base/lms-crm-integration/"
                    >
                      this link</a
                    >
                  </p>
                </div>
              </v-card-text>
              <v-footer color="transparent" absolute>
                <v-flex style="text-align: center !important">
                  <p
                    style="
                          font-size: 12px;
                          opacity: 0.9;
                          margin-bottom: 0px;
                        "
                  >
                    Feel free to reach out to us at
                    <a href="mailto:support@godial.cc">support@godial.cc</a>
                    if you are interested in these features
                  </p>
                </v-flex>
              </v-footer>
            </v-card>
          </v-col>
          <v-col>
            <v-row>
              <v-col cols="8">
                <h3 class="mt-3">Select Your Region</h3>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="selectedRegion"
                  :items="regionLists"
                  item-text="name"
                  item-value="url"
                  outlined
                  dense
                  class="py-2"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-card
              color="transparent"
              elevation="0"
              style="margin-top:25px; padding: 15px 50px; text-align: center"
            >
              <v-card-title primary-title class="justify-center"
                >Connect Your Zoho CRM</v-card-title
              >

              <v-card-text>
                <v-flex
                  xs12
                  sm10
                  offset-sm1
                  md10
                  offset-md1
                  style="margin-bottom: 10px"
                >
                  <v-btn
                    v-if="!zoho.enabled"
                    color="primarygrad"
                    dark
                    block
                    @click="connectToZoho"
                    >Connect</v-btn
                  >

                  <v-btn
                    v-if="zoho.enabled"
                    color="primarygrad"
                    dark
                    block
                    @click="connectToZoho"
                    >Reconnect</v-btn
                  >

                  <div style="padding-top: 1%">
                    <v-btn
                      v-if="zoho.enabled"
                      color="errorgrad"
                      dark
                      block
                      @click="disconnectFromZoho"
                      >Disconnect</v-btn
                    >
                  </div>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <v-row>
        <v-flex xs12 sm12 style="padding: 12px;">
          <v-card class="" color="primarygrad">
            <v-tabs
              v-model="tab"
              align-with-title
              background-color="primarygrad"
            >
              <v-tabs-slider color="yellow"></v-tabs-slider>

              <v-tab>
                Leads and Contacts
              </v-tab>
              <v-tab>
                Users
              </v-tab>
              <!-- <v-tab>
                Settings
              </v-tab> -->
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="4" style="padding-top: 2%;"
                        >Choose Where you want your leads to land
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-autocomplete
                          v-model="zoho.config.leadListId"
                          :disabled="!zoho.enabled"
                          class="pt-1"
                          :items="lists"
                          item-text="name"
                          item-value="id"
                          label="Lists"
                          prepend-inner-icon="view_list"
                          autocomplete="false"
                          outlined
                          dense
                        />
                      </v-col>

                      <v-col cols="12" sm="5" style="padding-top: 16px;">
                        <v-row>
                          <v-col>
                            <v-btn
                              color="infograd"
                              dark
                              block
                              :disabled="!zoho.enabled"
                              @click="setZoho('lead')"
                              >Save</v-btn
                            >
                          </v-col>

                          <v-col>
                            <v-btn
                              color="primarygrad"
                              :disabled="!zoho.enabled"
                              dark
                              block
                              @click="importZohoData('lead')"
                              >Lead Import</v-btn
                            >
                          </v-col>
                        </v-row>

                        <!-- <v-row>
                          <v-col v-if="zoho.config.watch">
                            Expiry :
                            {{
                              $moment(
                                zoho.config.watch.events[0].channel_expiry
                              ).format("MMM Do YYYY, h:mm:ss a")
                            }}
                          </v-col>
                        </v-row> -->
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="4" style="padding-top: 2%;"
                        >Choose Where you want your contacts to land
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-autocomplete
                          v-model="zoho.config.contactListId"
                          :disabled="!zoho.enabled"
                          class="pt-1"
                          :items="lists"
                          item-text="name"
                          item-value="id"
                          label="Lists"
                          prepend-inner-icon="view_list"
                          autocomplete="false"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col cols="12" sm="5" style="padding-top: 16px;">
                        <v-row>
                          <v-col>
                            <v-btn
                              color="infograd"
                              dark
                              block
                              :disabled="!zoho.enabled"
                              @click="setZoho('contact')"
                              >Save</v-btn
                            >
                          </v-col>

                          <v-col>
                            <v-btn
                              color="primarygrad"
                              :disabled="!zoho.enabled"
                              dark
                              block
                              @click="importZohoData('contact')"
                              >Contact Import</v-btn
                            >
                          </v-col>

                          <!-- <v-col>
                            <v-btn
                              color="notifygrad"
                              dark
                              block
                              v-if="zoho.config.watch == false"
                              @click="notifyZoho('contact')"
                              >Subscribe</v-btn
                            >

                            <v-btn
                              color="errorgrad"
                              dark
                              block
                              v-else
                              @click="disconnectNotifyZoho()"
                              >Unsubscribe</v-btn
                            >
                          </v-col> -->
                        </v-row>

                        <!-- <v-row>
                          <v-col v-if="zoho.config.watch">
                            Expiry :
                            {{
                              $moment(
                                zoho.config.watch.events[0].channel_expiry
                              ).format("MMM Do YYYY, h:mm:ss a")
                            }}
                          </v-col>
                        </v-row> -->
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="zoho.enabled"
                      align="center"
                      justify="center"
                      style="text-align: center;"
                    >
                      <v-col v-if="zoho.config.watch">
                        Notifications will auto renew on
                        {{
                          $moment(
                            zoho.config.watch.events[0].channel_expiry
                          ).format("MMM Do YYYY, h:mm:ss a")
                        }}
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-btn
                          v-if="zoho.config.watch == false"
                          color="notifygrad"
                          dark
                          block
                          :disabled="!zoho.enabled"
                          @click="notifyZoho('lead')"
                          >Subscribe to Live Sync</v-btn
                        >

                        <v-btn
                          v-else
                          color="errorgrad"
                          :disabled="!zoho.enabled"
                          dark
                          block
                          @click="disconnectNotifyZoho()"
                          >Unsubscribe to Live Sync</v-btn
                        >
                      </v-col>
                    </v-row>

                    <div class="d-flex" style="padding-top: 3%;">
                      <p class="my-2">
                        Sync After Call - Send call information to zoho after
                        every call
                      </p>
                      <v-spacer></v-spacer>
                      <v-switch
                        v-model="zohoAfterCallSet"
                        :disabled="!zoho.enabled"
                        class="my-2"
                        @change="zohoAfterCall()"
                      ></v-switch>
                    </div>
                  </v-card-text>
                </v-card>

                <!-- <v-card flat>
                  <v-card-text>

                    Choose Where you want your leads to land
                    <v-autocomplete
                      v-model="zoho.config.leadListId"
                      :disabled="!zoho.enabled"
                      class="pt-1"
                      :items="lists"
                      item-text="name"
                      item-value="id"
                      label="Lists"
                      prepend-inner-icon="view_list"
                      autocomplete="false"
                      outlined
                      dense
                    />

                    Choose Where you want your contacts to land
                    <v-autocomplete
                      v-model="zoho.config.contactListId"
                      :disabled="!zoho.enabled"
                      class="pt-1"
                      :items="lists"
                      item-text="name"
                      item-value="id"
                      label="Lists"
                      prepend-inner-icon="view_list"
                      autocomplete="false"
                      outlined
                      dense
                    />

                    Import existing Leads
                    <v-btn @click="importZohoData('lead')">Import Leads</v-btn>

                    Import existing Contacts
                    <v-btn @click="importZohoData('contact')">Import Contacts</v-btn>

                    <v-btn @click="setZoho">Save</v-btn>

                  </v-card-text>
                </v-card> -->
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row v-if="zoho.enabled && zohoUserAvailable">
                      <v-data-table
                        :headers="headers"
                        :items="zohoGodialUsers"
                        style="min-width: 78vw;"
                      >
                        <template v-slot:item.action="{ item }">
                          <v-btn
                            icon
                            color="error"
                            @click="removeGodialZohoUsers(item)"
                          >
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="5">
                        <v-autocomplete
                          v-model="godial.user"
                          :disabled="!zoho.enabled"
                          class="pt-1"
                          :items="allUsers"
                          item-text="name"
                          label="Godial Users"
                          prepend-inner-icon="person"
                          autocomplete="false"
                          outlined
                          dense
                          return-object
                        />
                      </v-col>
                      <v-col cols="12" sm="5">
                        <v-autocomplete
                          v-model="zoho.user"
                          :disabled="!zoho.enabled"
                          class="pt-1"
                          :items="zohoUsers"
                          item-text="full_name"
                          label="Zoho Users"
                          prepend-inner-icon="people"
                          autocomplete="false"
                          outlined
                          dense
                          return-object
                        />
                      </v-col>
                      <v-col cols="12" sm="2" style="padding-top: 16px;">
                        <v-btn
                          color="primarygrad"
                          :disabled="!zoho.enabled"
                          dark
                          block
                          @click="addZohoUser()"
                          >Add</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <!-- <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="10">
                        Zoho After Call
                        <span v-if="!$store.state.companyDetails.zohoAfterCall"
                          >( Already Disabled ,If you want to enable then click
                          the Enable button )</span
                        >

                        <span v-else
                          >( Already Enabled ,If you want to disable then click
                          the Disable button)
                        </span>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="2"
                        style="padding-top: 16px;"
                        v-if="!$store.state.companyDetails.zohoAfterCall"
                      >
                        <v-btn
                          color="primarygrad"
                          :disabled="!zoho.enabled"
                          dark
                          block
                          @click="zohoAfterCall(true)"
                          >Enable</v-btn
                        >
                      </v-col>

                      <v-col cols="12" sm="2" style="padding-top: 16px;" v-else>
                        <v-btn
                          color="errorgrad"
                          :disabled="!zoho.enabled"
                          dark
                          block
                          @click="zohoAfterCall(false)"
                          >Disable</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item> -->
            </v-tabs-items>
          </v-card>
        </v-flex>
      </v-row>
    </v-flex>

    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primary white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "../../js/firebase";

export default {
  data() {
    return {
      zoho: {
        type: "zoho",
        config: {
          leadListId: "",
          contactListId: "",
        },
        companyId: "",
        enabled: false,

        user: "",
      },
      godial: {
        user: "",
      },
      regionLists: [
        {
          name: "IN",
          url: "accounts.zoho.in",
        },
        {
          name: "US",
          url: "accounts.zoho.com",
        },
        {
          name: "EU",
          url: "accounts.zoho.eu",
        },
        {
          name: "AU",
          url: "accounts.zoho.com.au",
        },
        {
          name: "CN",
          url: "accounts.zoho.com.cn",
        },
        {
          name: "UK",
          url: "accounts.zoho.uk",
        },
      ],
      selectedRegion: "accounts.zoho.in",
      loader: false,
      lists: [],
      tab: 0,
      allUsers: [],
      zohoUsers: [],
      zohoUserAvailable: false,
      zohoGodialUsers: [],
      zohoAfterCallSet: false,
      headers: [
        // { text: "Paid", value: "paid" },
        { text: "Godial User", value: "name" },
        { text: "Zoho user", value: "zohoUser.zohoFullName" },
        { text: "Action", value: "action" },
      ],
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT", "companyDetails", "selectedTeam"]),
  },
  watch: {},
  created() {
    this.refresh();
    /* TODO Make it integration style and not use company */

    if (this.$route.query.code && this.$route.query["accounts-server"]) {
      this.verifyAccount(
        this.$route.query.code,
        this.$route.query["accounts-server"]
      );
    }

    this.zohoAfterCallSet = this.$store.state.companyDetails.zohoAfterCall;
  },
  methods: {
    async refresh() {
      await this.fetchLists();
      await this.getZohoDetails();
      await this.fetchAccounts();
      await this.getZohoUsers();
      await this.getGodialZohoUser();
    },

    async zohoAfterCall() {
      this.loader = true;

      console.log("this.zohoAfterCallSet", this.zohoAfterCallSet);

      var company = await this.$http.patch(
        `${this.ENDPOINT}/companies/${this.$store.state.user.companyId}`,
        {
          zohoAfterCall: this.zohoAfterCallSet,
        }
      );

      this.loader = false;

      this.$store.dispatch("getCompanyDetails", {
        companyId: this.$store.state.companyDetails.id,
      });

      this.refresh();
    },

    async disconnectNotifyZoho() {
      var response = await this.$http.post(
        `${this.ENDPOINT}/integrations/zoho/disconnectNotify`
      );

      console.log("response.body", response.body);

      if (response.body.watch[0].code == "SUCCESS") {
        this.$swal({
          title: "Unsubscribed",
          text: "Disconnected From Zoho",
          type: "success",
          confirmButtonText: "OK",
        });

        this.refresh();
      } else {
        this.$swal({
          title: "Unsubscribed",
          text: "Couldn't disconnect from Zoho",
          type: "error",
          confirmButtonText: "OK",
        });

        this.refresh();
      }
    },

    async notifyZoho(item) {
      var response = await this.$http.post(
        `${this.ENDPOINT}/integrations/zoho/notify`
      );

      console.log("response.body", response.body);

      if (response.body.events.length > 0) {
        this.$swal({
          title: "Subscribed",
          text: "Connected with Zoho",
          type: "success",
          confirmButtonText: "OK",
        });

        this.refresh();
      } else {
        this.$swal({
          title: "Subscribed",
          text: "Couldn't connect with Zoho",
          type: "error",
          confirmButtonText: "OK",
        });

        this.refresh();
      }

      console.log(response);
    },
    async removeGodialZohoUsers(item) {
      console.log(item);

      var self = this;

      try {
        var response = await self.$http.patch(
          `${self.ENDPOINT}/accounts/${item.id}`,
          {
            zohoUser: null,
          }
        );

        await self.refresh();

        console.log("response", response);
      } catch (e) {
        console.log(e);
      }
      // this.$store.dispatch("removeGodialZohoUser", item);
    },

    async addZohoUser() {
      var self = this;
      console.log(this.zoho.user);
      console.log(this.godial.user);

      if (this.godial.user == "" || typeof this.godial.user == "undefined") {
        this.$swal({
          title: "Error",
          text: "Please select a Godial user",
          type: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      if (this.zoho.user == "" || typeof this.zoho.user == "undefined") {
        this.$swal({
          title: "Error",
          text: "Please select a Zoho user",
          type: "error",
          confirmButtonText: "OK",
        });
        return;
      }

      var jsonObject = {
        zohoId: this.zoho.user.id,
        zohoFullName: this.zoho.user.full_name,
        zohoRole: this.zoho.user.role,
        zohoEmail: this.zoho.user.email,
        zohoProfile: this.zoho.user.profile,
      };

      try {
        var response = await self.$http.patch(
          `${self.ENDPOINT}/accounts/${this.godial.user.id}`,
          {
            zohoUser: jsonObject,
          }
        );

        this.refresh();

        console.log("response", response);
      } catch (e) {
        console.log(e);
      }
    },

    //Fetch Zoho Users
    async getZohoUsers() {
      var response = await this.$http.post(
        `${this.ENDPOINT}/integrations/zoho/users`
      );

      this.zohoUsers = response.body;

      console.log("response", response);
    },

    async getGodialZohoUser() {
      try {
        // const response = await this.$axios.get(
        //   `${this.ENDPOINT}/api/v1/godial/zoho/user`
        // );
        // if (response.data.success) {
        //   this.godial.user = response.data.data.user;
        //   this.zohoUserAvailable = true;
        // }
      } catch (error) {
        console.log(error);
      }
    },
    // Fetch Accounts
    async fetchAccounts() {
      this.allUsers = [];
      this.zohoGodialUsers = [];

      try {
        // var filter = {
        //   where: {
        //     companyId: this.$store.state.user.companyId,
        //     role: {
        //       neq: "ApiManager"
        //     }
        //   },
        //   fields: [
        //     "id",
        //     "name",
        //     "email",
        //     "teamsId",
        //     "picture",
        //     "pictureId",
        //     "zohoUser"
        //   ]
        // };

        var teams = [];
        teams = await this.getTeamIds();

        // filter.where["teamsId"] = {
        //   inq: teams
        // };

        var response = await this.$http.post(
          `${this.$store.state.ENDPOINT}/accounts/fetch`,
          {
            teams: teams,
          }
        );

        // var response = await this.$http.get(
        //   `${this.ENDPOINT}/accounts?filter=${encodeURIComponent(
        //     JSON.stringify(filter)
        //   )}`
        // );
        this.allUsers = response.body;

        // this.zoho.config.contactListId = this.allUsers[0].id;
        // if (
        //   this.$store.getters.user.role == "Manager" ||
        //   this.$store.getters.user.role == "Teammanager"
        // ) {
        //   this.allUsers.unshift({
        //     id: "allMembers",
        //     name: "All",
        //   });
        // }

        for (var x in this.allUsers) {
          console.log(this.allUsers[x]);

          if (
            typeof this.allUsers[x].zohoUser != "undefined" ||
            this.allUsers[x].zohoUser != null
          ) {
            var isEmpty = _.isEmpty(this.allUsers[x].zohoUser);

            if (!isEmpty) {
              this.zohoGodialUsers.push(this.allUsers[x]);
            }
          }
        }

        console.log("this.zohoGodialUsers", this.zohoGodialUsers);

        if (this.zohoGodialUsers.length > 0) {
          this.zohoUserAvailable = true;
        } else {
          this.zohoUserAvailable = false;
        }
      } catch (e) {
        console.log(e);

        return this.$swal({
          type: "error",
          text: "Error while fetching accounts",
        });
      }
    },

    async verifyAccount(code, url) {
      this.loader = true;
      if (typeof code == "object") {
        code = code[0];
      }
      if (typeof url == "object") {
        url = url[0];
      }
      try {
        var response = await this.$http.post(
          `${this.ENDPOINT}/integrations/zoho/connect`,
          {
            code,
            url,
          }
        );
        this.loader = false;
        this.$swal({
          type: "success",
          text: "Authentication Complete with Zoho",
        });

        this.$store.dispatch("getCompanyDetails", {
          companyId: this.companyDetails.id,
        });

        this.refresh();
        window.location.href = window.location.href.split("?")[0];
      } catch (e) {
        this.loader = false;
        console.log(e);
        this.$swal({ type: "error", text: e.body.error.message });
      }
    },
    async connectToZoho() {
      var self = this;
      var client_id = "1000.D1LSYJOFG9MA5KWNKBE4RQ5IAPOB1U";
      var redirect_uri = encodeURIComponent(process.env.VUE_APP_ZOHO_URI);
      //generate a random 10 digit id
      var state = Math.floor(Math.random() * 10000000000);
      var url = `https://${self.selectedRegion}/oauth/v2/auth?state=${state}&scope=ZohoCRM.modules.calls.WRITE,ZohoCRM.modules.calls.CREATE,ZohoCRM.modules.leads.READ,ZohoCRM.modules.leads.CREATE,ZohoCRM.modules.leads.WRITE,ZohoCRM.modules.contacts.READ,ZohoCRM.modules.contacts.CREATE,ZohoCRM.modules.contacts.WRITE,ZohoCRM.settings.ALL,ZohoCRM.users.READ,ZohoCRM.notifications.ALL&client_id=${client_id}&response_type=code&access_type=offline&redirect_uri=${redirect_uri}`;
      window.open(url, "_blank");
    },
    async disconnectFromZoho() {
      //TODO ensure that the subscribed hooks are stopped
      await this.$http.post(`${this.ENDPOINT}/integrations/zoho/disconnect`);
      this.getZohoDetails();
      this.$store.dispatch("getCompanyDetails", {
        companyId: this.companyDetails.id,
      });
    },
    async importZohoData(from) {
      console.log(from);

      if (from == "lead") {
        console.log(this.zoho.config.leadListId);

        if (typeof this.zoho.config.leadListId == "undefined") {
          this.loader = false;
          return this.$swal({
            type: "error",
            text: "Please Select A Lists And Set Zoho",
          });
        }
      } else {
        if (typeof this.zoho.config.contactListId == "undefined") {
          this.loader = false;
          return this.$swal({
            type: "error",
            text: "Please Select A Lists And Set Zoho",
          });
        }
      }

      this.$http.post(`${this.ENDPOINT}/integrations/zoho/import`, {
        from: from,
      });
    },

    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }

      teams = _.without(teams, "allTeams");

      return teams;
    },
    async fetchLists() {
      try {
        this.lists = [];
        var response = [];

        var teamIds = this.getTeamIds();

        // Teams sections
        response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
          teams: teamIds,
        });
        this.lists = response.body;

        // this.zoho.config.leadListId = this.lists[0].id;

        if (this.lists.length < 1) {
          this.loader = false;
          return;
        }
      } catch (e) {
        this.loader = false;
        console.log("error in list fetch", e);
      }
    },

    async setZoho(item) {
      this.loader = true;

      console.log(item);

      if (item == "lead") {
        console.log(this.zoho.config.leadListId);

        if (typeof this.zoho.config.leadListId == "undefined") {
          this.loader = false;
          return this.$swal({
            type: "error",
            text: "Please Select A Lists Where Leads Will Be Added",
          });
        }
      } else {
        if (typeof this.zoho.config.contactListId == "undefined") {
          this.loader = false;
          return this.$swal({
            type: "error",
            text: "Please Select A Lists Where Contacts Will Be Added",
          });
        }
      }

      try {
        this.zoho.companyId = this.companyDetails.id;
        this.zoho.type = "zoho";
        var res = {};
        //If new user
        if (typeof this.zoho.id == "undefined") {
          // Add zoho Details In Company Data
          res = await this.$http.post(
            `${this.ENDPOINT}/integrations`,
            this.zoho
          );
        } else {
          res = await this.$http.put(
            `${this.ENDPOINT}/integrations/${this.zoho.id}`,
            this.zoho
          );
        }

        // Success Message
        if (res.status == 200) {
          // Set Company Object for Store dispatch

          this.loader = false;

          try {
            fb.log("zoho_added");
          } catch (e) {
            console.log("Error in FB log", e);
          }

          return this.$swal({
            type: "success",
            text: "zoho Added to Godial Successfully",
          });
        } else {
          return this.$swal({
            type: "warning",
            text: "Something Went Wrong",
          });
        }
      } catch (err) {
        console.log(err);
        this.loader = false;
        return this.$swal({
          type: "warning",
          text: err,
        });
      }
    },
    async getZohoDetails() {
      var resp = await this.$http.get(
        `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.companyDetails.id}&filter[where][type]=zoho`
      );

      if (resp.body.length > 0) this.zoho = resp.body[0];
    },
  },
};
</script>

<style></style>
